import { AnimatePresence, motion } from 'framer-motion'
import { ArrowLeft, Close, QuestionMark } from 'ui/icons'
import { errorTextAnimation, fadeAnimation } from 'ui/animations/animations'
import { useEffect, useState } from 'react'

import Button from 'components/Button/Button'
import CompanyLogo from 'components/CompanyLogo/CompanyLogo'
import { getGraphqlError } from 'utils/generalUtils'
import media from 'styled-media-query'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'
import Consent from 'components/Consent/Consent'

export default function NewMember({ ...props }) {
  const set = useStore((state) => state.set)
  const username = useStore((state) => state.username)
  const name = useStore((state) => state.name)
  const email = useStore((state) => state.email)
  const posts = useStore((state) => state.posts)
  const policy = useStore((state) => state.policy)
  const origin = useStore((state) => state.origin)
  const setDiscard = useStore((state) => state.setDiscard)
  const setShouldClose = useStore((state) => state.setShouldClose)
  const actions = useStore((state) => state.actions.upload)
  const { getString } = useTranslations()

  const [emailError, setEmailError] = useState(undefined)
  const [nameError, setNameError] = useState(undefined)

  const nameErrorMessage = getString('invalidName', 'Invalid name')
  const nameErrorMessageShort = getString(
    'invalidNameShort',
    'Must be at least 3 characters long'
  )
  const nameErrorMessageLong = getString(
    'invalidNameLong',
    'Cannot exceed 40 characters'
  )
  const emailErrorMessage = getString('invalidEmail', 'Invalid email')

  const onChangeName = (e) => {
    const { value } = e.target

    validateName(value)

    set((state) => {
      state['name'] = value
    })
  }

  const validateName = (name) => {
    if (!name) {
      return setNameError(nameErrorMessage)
    }

    const namePattern = /^.{3,40}$/

    const valid = namePattern.test(name)

    if (!valid) {
      if (name.length < 3) {
        return setNameError(nameErrorMessageShort)
      }
      if (name.length > 40) {
        return setNameError(nameErrorMessageLong)
      }
    }

    setNameError(valid ? undefined : nameErrorMessage)
    return valid
  }

  const onChangeEmail = (e) => {
    const { value } = e.target

    validateEmail(value)

    set((state) => {
      state['email'] = value
    })
  }

  const validateEmail = (email) => {
    if (!email) {
      return setEmailError(emailErrorMessage)
    }

    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = emailPattern.test(email)
    setEmailError(valid ? undefined : emailErrorMessage)
    return valid
  }

  const submitPosts = async (e) => {
    e.preventDefault()

    if (!name) {
      return setNameError(nameErrorMessage)
    }
    if (!email) {
      return setEmailError(emailErrorMessage)
    }

    try {
      await actions.verifyMemberBeforeUpload(true)
    } catch (error) {
      const message = getGraphqlError(error)

      if (
        message === 'Member already exists' ||
        message === 'A member with the provided email already exists' ||
        message ===
          'A member with the provided instagram account already exists'
      ) {
        setEmailError(emailErrorMessage)
        return
      }
      return
    }

    set((state) => {
      state.view = 'uploading'
    })

    actions.uploadPosts(posts, true)
  }

  useEffect(() => {
    if (username) {
      set((state) => {
        state['name'] = username
      })
      validateName(username)
    }

    if (email) {
      validateEmail(email)
    }
  }, [])

  return (
    <AnimatePresence exitBeforeEnter>
      <form onSubmit={submitPosts}>
        <Style {...props}>
          <motion.div
            key={'new-member'}
            className={'new-member'}
            variants={fadeAnimation}
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
          >
            <div className="header">
              <div
                className="back-arrow"
                onClick={() =>
                  set((state) => {
                    state.view = 'edit-posts'
                  })
                }
              >
                <ArrowLeft />
              </div>
              <div className="right">
                {origin && (
                  <div
                    className="close"
                    onClick={() => {
                      setDiscard(true)
                      setShouldClose(true)
                    }}
                  >
                    <Close />
                  </div>
                )}
              </div>
            </div>
            <div className="body">
              <CompanyLogo />
              <div className="title">
                <h1>{getString('yourInformationTitle', 'Your information')}</h1>
                <p>
                  {getString(
                    'newMemberDescription',
                    'Fill in the details below'
                  )}
                </p>
              </div>
              <div className={`input-container ${nameError ? 'hasError' : ''}`}>
                <div className="input-label">
                  <label htmlFor="name">{getString('name', 'Name')}</label>
                  <AnimatePresence>
                    {nameError && (
                      <motion.div
                        key="name-error"
                        variants={errorTextAnimation}
                        initial="hidden"
                        exit="hidden"
                        animate="show"
                        className="error-container"
                      >
                        <div className="error">{nameError}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <input
                  name="name"
                  type="text"
                  id="name"
                  placeholder={getString('enterName', 'Enter name...')}
                  value={name}
                  onChange={onChangeName}
                  autoComplete="off"
                  autoCorrect="off"
                  autoFocus={!username ? true : false}
                />
              </div>

              <div
                className={`input-container ${emailError ? 'hasError' : ''}`}
              >
                <div className="input-label">
                  <label htmlFor="email">{getString('email', 'Email')}</label>
                  <AnimatePresence>
                    {emailError && (
                      <motion.div
                        key="email-error"
                        variants={errorTextAnimation}
                        initial="hidden"
                        exit="hidden"
                        animate="show"
                        className="error-container"
                      >
                        <div className="error">{emailError}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <input
                  name="email"
                  type="email"
                  id="email"
                  placeholder={getString('enterEmail', 'Enter email...')}
                  value={email}
                  onChange={onChangeEmail}
                  autoCorrect="off"
                  autoFocus={!username ? false : true}
                />
              </div>
            </div>
            <div className="footer">
              <motion.div className="submit">
                <Consent items={[policy]}>
                  <Button
                    disabled={
                      emailError ||
                      email.length === 0 ||
                      nameError ||
                      name.length === 0
                    }
                    color="primary"
                    expanded
                    type="submit"
                  >
                    {getString('upload', 'Upload')}
                  </Button>
                </Consent>
              </motion.div>
            </div>
          </motion.div>
        </Style>
      </form>
    </AnimatePresence>
  )
}

const Style = styled.div`
  .new-member {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: 100dvh;

    ${media.greaterThan('medium')`
      justify-content: unset;
      height: auto;
      max-width: 500px;
      min-height: auto;
      min-width: 480px;
    `}
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px var(--spacing-sm);

    ${media.greaterThan('medium')`
      padding: var(--spacing-sm);
    `}

    .back-arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      &:hover {
        background: var(--light-active);
      }
    }

    .right {
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-xs);
    }

    .info-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      a {
        display: flex;
      }

      &:hover {
        background: var(--light-active);
      }
    }

    .close {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 100px;

      ${media.greaterThan('medium')`
          display: none;
        `}

      &:hover {
        background: var(--light-active);
      }
    }
  }

  .body {
    flex-direction: column;
    grid-gap: var(--spacing-sm);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: 0px var(--spacing-md) var(--spacing-md);
    `}

    .title {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;

      p {
        color: var(--text-opacity);
      }
    }

    .input-container {
      position: relative;
      display: flex;
      flex-direction: column;
      grid-gap: var(--spacing-xs);
      width: 100%;

      .input-label {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          font-size: 14px;
          color: var(--text-opacity);
        }

        .error {
          font-size: 14px;
        }
      }

      input {
        outline: none;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 1px solid var(--border);
        border-radius: 6px;
        height: 47px;
        padding: 15px;
        transition: 0.15s ease-in-out;

        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }

    .error-container {
      .error {
        color: var(--negative);
      }
    }
  }

  .footer {
    padding: 20px;

    ${media.greaterThan('medium')`
      padding: 0px var(--spacing-md) var(--spacing-md);
    `}

    .submit {
      text-align: center;
      display: flex;
      flex-direction: column;
      grid-gap: var(--spacing-sm);
    }
  }
`
